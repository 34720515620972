
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';

import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import GenericMixin from '@/lib/mixins/GenericMixin';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModuleV2 from '@/store/modules/tasqProductionDataChartModuleV2';
import padSignalsModule from '@/store/modules/padSignalsModule';
import TasqListMixin from '@/components/tasqsCommon/TasqListMixin';

// @ts-ignore
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqDetails: () => getComponent('tasqs/TasqDetailV2'),
    WorkTicketDetail: () => getComponent('workTickets/WorkTicketDetail'),
    AddWorkTicket: () => getComponent('workTickets/AddWorkTicket'),
    ActionBubble: () => getComponent('tasqs/ActionBubble'),
   TasqAiPopup: () => getComponent('gpt/TasqAIPopup'),
  },
})
export default class TasqsDesktop extends mixins(
  TasqListMixin,
  GenericMixin
) {

  screenWidthLarge = window.innerWidth > 1400

  searchQuery: any = ''

  showTasqAi =  false

  async created() {
    if(this.$route.query && this.$route.query.query){
      this.searchQuery = this.$route.query.query
    }
    this.$nextTick(() => {
      this.showTasqAi =  true
    })


    await this.prepareListView();
    this.completedVisible = false;
    // await this.setupDataFetching();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });


  }

  async onResize() {
    this.screenWidthLarge = window.innerWidth > 1400;
  }


}
